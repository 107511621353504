import axios from '@/common/js/request'

export function bzMessageConfig_pageMessageTemplate(data){
    const bzMessageConfig_pageMessageTemplate = axios({
        url: '/bzMessageConfig/pageMessageTemplate',
        method: 'get',
        params:data
    })
    return bzMessageConfig_pageMessageTemplate
}
export function bzMessageConfig_getTemplateDetail(data){
    const bzMessageConfig_getTemplateDetail = axios({
        url: '/bzMessageConfig/getTemplateDetail',
        method: 'get',
        params:data
    })
    return bzMessageConfig_getTemplateDetail
}
export function bzMessageConfig_saveMessageTemplate(data){
    const bzMessageConfig_saveMessageTemplate = axios({
        url: '/bzMessageConfig/saveMessageTemplate',
        method: 'post',
        data:data
    })
    return bzMessageConfig_saveMessageTemplate
}
export function bzMessageConfig_setTemplateStatus(data){
    const bzMessageConfig_setTemplateStatus = axios({
        url: '/bzMessageConfig/setTemplateStatus',
        method: 'get',
        params:data
    })
    return bzMessageConfig_setTemplateStatus
}
export function bzMessageConfig_pageTimePlan(data){
    const bzMessageConfig_pageTimePlan = axios({
        url: '/bzMessageConfig/pageTimePlan',
        method: 'get',
        params:data
    })
    return bzMessageConfig_pageTimePlan
}
export function bzMessageConfig_getTimePlanDetail(data){
    const bzMessageConfig_getTimePlanDetail = axios({
        url: '/bzMessageConfig/getTimePlanDetail',
        method: 'get',
        params:data
    })
    return bzMessageConfig_getTimePlanDetail
}
export function bzMessageConfig_saveTimePlan(data){
    const bzMessageConfig_saveTimePlan = axios({
        url: '/bzMessageConfig/saveTimePlan',
        method: 'post',
        data:data
    })
    return bzMessageConfig_saveTimePlan
}
export function role_selectRoleList(data){
    const role_selectRoleList = axios({
        url: '/post/selectPostList',
        method: 'get',
        params:data
    })
    return role_selectRoleList
}
export function user_selectUserDeptPageList(data){
    const user_selectUserDeptPageList = axios({
        url: '/user/selectUserDeptPageList',
        method: 'get',
        params:data
    })
    return user_selectUserDeptPageList
}
export function bzMessageConfig_setTimePlanStatus(data){
    const bzMessageConfig_setTimePlanStatus = axios({
        url: '/bzMessageConfig/setTimePlanStatus',
        method: 'get',
        params:data
    })
    return bzMessageConfig_setTimePlanStatus
}
export function bzMessageConfig_getHourLongDetail(data){
    const bzMessageConfig_getHourLongDetail = axios({
        url: '/bzMessageConfig/getHourLongDetail',
        method: 'get',
        params:data
    })
    return bzMessageConfig_getHourLongDetail
}
export function bzMessageConfig_saveHourLong(data){
    const bzMessageConfig_saveHourLong = axios({
        url: '/bzMessageConfig/saveHourLong',
        method: 'post',
        data:data
    })
    return bzMessageConfig_saveHourLong
}
export function bzMessageConfig_getNotWorkDay(data){
    const bzMessageConfig_getNotWorkDay = axios({
        url: '/bzMessageConfig/getNotWorkDay',
        method: 'get',
        params:data
    })
    return bzMessageConfig_getNotWorkDay
}
export function bzMessageConfig_getWorkHoliday(data){
    const bzMessageConfig_getWorkHoliday = axios({
        url: '/bzMessageConfig/getWorkHoliday',
        method: 'get',
        params:data
    })
    return bzMessageConfig_getWorkHoliday
}
export function bzMessageConfig_getWeekDay (data){
    const bzMessageConfig_getWeekDay  = axios({
        url: '/bzMessageConfig/getWeekDay',
        method: 'get',
        params:data
    })
    return bzMessageConfig_getWeekDay 
}
export function bzMessageConfig_saveNotWorkDay(data){
    const bzMessageConfig_saveNotWorkDay  = axios({
        url: '/bzMessageConfig/saveNotWorkDay',
        method: 'post',
        data:data
    })
    return bzMessageConfig_saveNotWorkDay 
}

