<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
      
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
             
            :stripe="true"
            class="table-height"
            
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="remark"
              label="简要描述"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="sourceName"
              label="报装来源"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
              prop="timeHour"
              label="时效(工作日)"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            
           
            <el-table-column
              prop="startRemindHour"
              label="开始提醒设置(工作日)"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="remindDayName"
              label="提醒日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="接受人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="statusName" label="状态" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createName" label="创建人" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                <el-button type="text" size="small" @click="openDia(row)"   >编辑</el-button>
                <el-button type="text" size="small" @click="chageStatus(row)" >{{row.statusCode==1?'暂停':'开启'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      >
      <el-form ref="form" :model="form" label-width="150px" size="mini" class="form">
       
        <el-form-item label="计算规则:">
          从报装申请单申请时间开始计算，满足时效X工作日要求，且到达Y工作日或已超出，生成提醒消息；其中X，Y在规则中进行设置
        </el-form-item>
        <el-form-item label="简要描述:">
          {{form.remark}}
        </el-form-item>
        <el-form-item label="报装来源:">
          <el-select v-model="form.sourceCode" multiple placeholder="请选择">
          <el-option
            v-for="item in sourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="执行频次">
          <el-radio-group v-model="form.rateCode" @change="rateCode">
            <el-radio label="1">分<el-input v-model="form.rateValue1" style="width:60px" class="input"></el-input></el-radio>
            <el-radio label="2">时<el-input v-model="form.rateValue2" style="width:60px" class="input"></el-input></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="时效">
          <el-input v-model="form.timeHour"  @change="getWorkTime('timeHour','timeDay',1)"  style="width:60px" class="input"></el-input>小时
          <!-- 或
          <el-input v-model="form.timeDay" @change="getWorkTime('timeDay','timeHour',2)" style="width:60px" class="input"></el-input>工作日 -->
        </el-form-item>
        <el-form-item label="开始提醒设置">
           <el-input v-model="form.startRemindHour" @change="getWorkTime('startRemindHour','startRemindDay',1)"  style="width:60px" class="input"></el-input>小时
           <!-- 或
          <el-input v-model="form.startRemindDay" @change="getWorkTime('startRemindDay','startRemindHour',2)"  readonly style="width:60px" class="input"></el-input>工作日 -->
        </el-form-item>
        <el-form-item label="提醒日期">
          <el-radio-group v-model="form.remindDayType">
            <el-radio label="1">无节假日</el-radio>
            <el-radio label="2">工作日</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="接收人">
          <el-radio-group v-model="form.receiverTypeCode" @change="receiverType">
          <div>
            <el-radio label="1" v-if="form.receiverTypeCode!=2">按岗位设置</el-radio>
            <el-select  v-if="form.receiverTypeCode!=2" v-model="form.receiverRoleId" multiple placeholder="请选择">
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-radio label="2"  v-if="form.receiverTypeCode==2">特定执行人</el-radio>
          </div>
          <div>
            <el-radio label="3"  v-if="form.receiverTypeCode!=2">固定接收人</el-radio>
            <el-select  v-if="form.receiverTypeCode!=2" v-model="form.receiverUserId" multiple placeholder="请选择">
              <el-option
                v-for="item of userList"
                :key="item.userId+''"
                :label="item.realname"
                :value="item.userId+''">
              </el-option>
            </el-select>
          </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="消息实例:">
          <el-input
            type="textarea"
            class="input"
            :rows="4"
            placeholder="请输入内容"
            v-model="form.template" disabled>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="saveadd()" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {bzMessageConfig_pageTimePlan,bzMessageConfig_getTimePlanDetail,bzMessageConfig_saveTimePlan,role_selectRoleList,user_selectUserDeptPageList,bzMessageConfig_setTimePlanStatus,bzMessageConfig_getHourLongDetail} from '../../RequestPort/settings/settings'
export default {
  name: 'settings',

  data() {
    return {
      total: 10,
      search: { current: 1, size: 100},
      tableData:[{}],
      dialogVisible:false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        rateValue2:'',
        rateValue1:''
      },
      roleList:[],
      userList:[],
      textarea:'',
      sourceList:[{ label: '爱山东', value: '1' },{ label: '掌上聊城', value: '2' },{ label: '公众号', value: '3' },{ label: '官网', value: '4' },{ label: '客服提交', value: '5' }],
      dateLimit:{}
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getWorkTime(changeQ,changeDate,type){
        const limit = this.dateLimit.hourLong
        // if(!this.dateLimit.hourLong){
        //   this.$message.error('请进行工作时间的设置！')
        // }
        if(type==1){
          var pat = /^[0-9]*$/; 
          if(!pat.test(this.form[changeQ])){
            this.$message.error('小时不能输入小数')
            return
          }
        }
        // if(type==1){
        //   this.form[changeDate] = (this.form[changeQ]/limit).toFixed(2)
        // }
        // if(type==2){
        //   this.form[changeDate] = this.form[changeQ]*limit
        // }
    },
    openDia(row){
       bzMessageConfig_getHourLongDetail({}).then(res=>{
          this.dateLimit = res.data
         
        })
      bzMessageConfig_getTimePlanDetail({id:row.id}).then(res=>{
        this.form = res.data;
        if(res.data.sourceCode){
          this.form.sourceCode = res.data.sourceCode.split(',')
        }
        if(res.data.receiverRoleId){
            this.form.receiverRoleId = res.data.receiverRoleId.split(',') 
        }else{
          this.form.receiverRoleId = []
        }
        if( res.data.receiverUserId){
          this.form.receiverUserId = res.data.receiverUserId.split(',') 
        }else{
          this.form.receiverUserId = []
        }
        this.form.rateCode = this.form.rateCode+''
        if(this.form.rateCode==1){
          this.$set(this.form,'rateValue1',this.form.rateValue)
           this.$set(this.form,'rateValue2','')
        }
        if(this.form.rateCode==2){
          this.$set(this.form,'rateValue2',this.form.rateValue)
           this.$set(this.form,'rateValue1','')
        }
        this.form.remindDayType = this.form.remindDayType+''
        this.form.receiverTypeCode = this.form.receiverTypeCode+''
        this.dialogVisible = true
      })
      role_selectRoleList({}).then(res=>{
        this.roleList = res.data;
      })
      user_selectUserDeptPageList({current:1,size:100000}).then(res=>{
        this.userList = res.data.records
      })
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    getList(current) {
      if (current) {
        this.search.current = 1
      }
      //查列表
      bzMessageConfig_pageTimePlan(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    setFrom(){
      this.form={
        id:'',
        rateValue:'',
        sourceCode:[],
        template:'',
        rateValue2:'',
        rateValue1:''
      }
    },
    rateCode(val){
      console.log(val)
      if(val==1){
        this.$set(this.form,'rateValue2','')
      }
      if(val==2){
        this.$set(this.form,'rateValue1','')
      }
    },
     receiverType(val){
        if(val==1){
          this.form.receiverUserId =[]
        }
        if(val==2){
          this.form.receiverRoleId =[]
          this.form.receiverUserId =[]
        }
        if(val==3){
          this.form.receiverRoleId =[]
        }
    },
    saveadd(){
      const listArr = [];
      this.form.sourceCode.forEach(element => {
          const name = this.sourceList.find(el=>el.value==element)
          listArr.push(name.label);
      });
      this.form.sourceName = listArr.join(',');
      this.form.sourceCode = this.form.sourceCode.join(',')
      if(this.form.rateCode==1){
        this.form.rateValue = this.form.rateValue1
      }
      if(this.form.rateCode==2){
        this.form.rateValue = this.form.rateValue2
      }
      this.form.receiverRoleId = this.form.receiverRoleId.join(',') 
      this.form.receiverUserId = this.form.receiverUserId.join(',') 
      bzMessageConfig_saveTimePlan(this.form).then(ret=>{
        if(ret.code==200){
          this.$message.success('编辑成功！')
          this.setFrom();
          this.dialogVisible = false;
          this.getList();
        }else{
          this.$message.error(ret.msg)
        }
      })
    },
    chageStatus(row){
    const showNmae = row.statusCode==1?'暂停':'开启'
    bzMessageConfig_setTimePlanStatus({id:row.id,statusCode:row.statusCode==1?0:1}).then(ret=>{
       if(ret.code==200){
          this.$message.success(showNmae+'成功！')
          this.getList();
        }else{
          this.$message.error(ret.msg)
        }
    })
  }
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
   height: auto;
}
.table-height{height: calc(100vh - 270px);}
.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
.input{
  width: 318px;
}
// .form{
//   margin-left:3%;
// }
</style>
